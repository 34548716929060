import React from 'react';

import { Layout } from 'antd';
import cn from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link'

import NavBar from '@components/Navbar';
import { HOME_PATH } from '@constants/index'
import { Button } from '@element/index'
import I18n from '@utils/i18n';

import styles from './index.module.css';

const { Header, Content } = Layout

const Page404 = () => {
  return (
    <Layout>
      <Header>
        <NavBar type={'2'}/>
      </Header>
      <Content className={styles.body}>
        <div className={styles.img}>
          <Image src={require('@images/404.webp')} layout="responsive" />
        </div>
        <p className={cn('Medium', styles.p)}>{I18n.t('lKG61lee')}</p>
        <Button type="primary" className={styles.btn}>
          <Link legacyBehavior href={HOME_PATH}>
            <a className={cn(styles.btnText, 'Medium')}>
              <span>
                {I18n.t('GQKgJQUE')}
              </span>
            </a>
          </Link>
        </Button>
      </Content>
    </Layout>
  );
}

export default Page404;
